@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600&family=Inter:wght@400;500;600&family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600&family=Open+Sans:wght@400;600&family=Roboto:wght@400;500;700&display=swap');
@import url('@100mslive/roomkit-react/index.css');

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: env(safe-area-inset);
}

#root {
  overscroll-behavior-y: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
}

/* scroll bar */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #657080;
  border-radius: 5px;
}

code,
kbd,
samp,
pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:focus {
  outline: none;
}
/**
 * needed for safari. Safari
 * adds background color on its own
 * on hls-viewer on fullscreen
 */
#hls-viewer-dark:fullscreen {
  background-color: black !important;
}

#hls-viewer-light:fullscreen {
  background-color: white !important;
}
